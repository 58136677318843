<template>
  <div class="task-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
      <div class="green-label">Filters</div>
      <search-filters
        :filters="filters"
        @change="changeFilter"
        @press-enter="applyFilter"
        ref="searchFilters"
      ></search-filters>
      <div>
        <b-button
          @click="applyFilter">Apply</b-button>
        <b-button
          @click="resetFilters">Reset</b-button>
      </div>

    </div>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="templateList" :fields="templateFields" select-mode="single" selected-variant="success"
             selectable
             th-class="bv-table-header" :current-page="currentPage" :per-page="perPage"
             :filter="filterMethod === 'local' ? filter : undefined"
             :filter-included-fields="filterFields"
             @row-selected="onRowSelected"
             class="bv-table">
      <!--Activated Checkbox Row Element-->
      <template v-slot:cell(is_public)="row">
        <b-form-checkbox v-model="row.item.is_public" disabled></b-form-checkbox>
      </template>
      <template v-slot:cell(icon)="row">
        <span v-if="row.item.icon">
          <i class="mr-3 fa" :class="row.item.icon" :style="'color: ' + row.item.color + ';'"></i>
        </span>
      </template>

      <template v-slot:cell(actions)="row">
        <i
            class="row-icon-button fa fa-calendar mr-2"
            v-b-tooltip.hover
            title="View Audit Log"
            @click="showAuditLog(row.item, $event)"
        ></i>
        <i
            class="row-icon-button-danger"
            :class="$config.icons.general.remove"
            v-b-tooltip.hover
            title="Delete"
            v-if="!row.item.disable_delete"
            @click="deleteTemplate(row.item, $event)"
        ></i>
      </template>
    </b-table>
    <div class="green-divider"></div>
        <div class="footer">
          <div class="table-results">
            Results: {{totalRows}}
          </div>
          <div class="page-bar">
            <b-form-select
              variant="dark"
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              class="page-select">
            </b-form-select>
            <b-pagination
              variant="dark"
              v-model="currentPage"
              :total-rows="templateList.length"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="page-buttons"></b-pagination>
          </div>
        </div>
    <b-modal id="modal-templates-auditlog" centered class="modal-content" size="lg" title="Templates Auditlog"
             ok-only ok-title="Close">
      <AuditLog v-if="auditLogTemplate" related_type="template" :related_id="auditLogTemplate.template_id"></AuditLog>
    </b-modal>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'

import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'
import * as AlertHelper from '../helpers/AlertHelper'
import SearchFilters from '@/components/shared/SearchFilters.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'

export default {
  name: 'admin-template-list',
  components: {
    AuditLog,
    SearchFilters,
    LoadingBox
  },
  props: {
    templates: Array
  },
  data: function () {
    return {
      loading: true,
      filterMethod: 'local', // Filter Locally or remotely at the server-side
      filter: '',
      filterFields: null,
      currentTask: null,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      templateFields: [
        { key: 'template_id',
          label: 'Id',
          sortable: true
        },
        { key: 'revision_number',
          label: 'Revision #',
          sortable: true
        },
        { key: 'icon',
          label: 'Icon',
          sortable: false
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'created',
          label: 'Created',
          sortable: true
        },
        { key: 'valid_related_types',
          label: 'Types',
          sortable: true
        },
        { key: 'owner_id',
          label: 'Owner',
          sortable: true
        },
        { key: 'is_public',
          label: 'Public',
          sortable: true
        },
        { key: 'actions',
          label: 'Actions'
        }
      ],
      filters: [
        {
          label: 'Filter By Status',
          type: 'select',
          placeholder: 'Status...',
          fieldName: 'status',
          options: [
            { value: 'COMPLETE', text: 'Complete' },
            { value: 'FAILED', text: 'Failed' },
            { value: 'INPROG', text: 'In Progress' },
            { value: 'QUEUED', text: 'Queued' },
            { value: 'NEW', text: 'New' }
          ]
        },
        {
          label: 'Filter By Owner',
          type: 'string',
          placeholder: 'Owner Id',
          fieldName: 'owner_id'
        }
      ],
      currentFilter: {},
      templateList: [],
      auditLogTemplate: null
    }
  },
  async created () {
    if (this.templates) {
      this.templateList = this.templates
    } else {
      await this.refresh()
    }
    this.loading = false
  },
  methods: {
    applyFilter: function () {
      this.refresh()
    },
    changeFilter: function (filters) {
      console.log('Set Filters: ', filters)
      this.currentFilter = filters
    },
    resetFilters: function () {
      this.$refs.searchFilters.reset()
      this.refresh()
    },
    refresh: async function () {
      let resp = await DataProvider.adminGetTemplates()
      if (resp.success) {
        console.log(resp.data)
        let templates = resp.data.templates
        // // Apply filters locally, we might add them to the API later if needed
        // if (Object.hasOwn(this.currentFilter, 'status')) {
        //   tasks = tasks.filter(t => t.status === this.currentFilter.status)
        // }
        // if (Object.hasOwn(this.currentFilter, 'owner_email')) {
        //   this.filterFields = ['owner_email']
        //   this.filter = this.currentFilter.owner_email
        // } else {
        //   this.filterFields = []
        //   this.filter = ''
        // }
        this.templateList = templates
        this.totalRows = templates.length
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm')
    },
    async deleteTemplate(template, event) {
      event.stopPropagation()
      let confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this Template?')

      if (confirm) {
        let resp = await DataProvider.deleteTemplate(template.template_id)
        if (resp.success) {
          AlertHelper.successToast('Template Deleted', 'Template Deleted')
          this.refresh()
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    onSave: async function () {
      this.refresh()
    },
    onRowSelected (row) {
      console.log('sel')
      this.$emit('row-selected', row[0])
    },
    showAuditLog(template, $event) {
      $event.stopPropagation()
      this.auditLogTemplate = template
      this.$bvModal.show('modal-templates-auditlog')
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .task-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
  }

  .table-results {
    color: $theme-color-primary-4;
    font-weight: 600;
  }
</style>
