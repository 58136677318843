var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "filter-bar" },
        [
          _c("div", { staticClass: "green-label" }, [_vm._v("Filters")]),
          _c("search-filters", {
            ref: "searchFilters",
            attrs: { filters: _vm.filters },
            on: { change: _vm.changeFilter, "press-enter": _vm.applyFilter }
          }),
          _c(
            "div",
            [
              _c("b-button", { on: { click: _vm.applyFilter } }, [
                _vm._v("Apply")
              ]),
              _c("b-button", { on: { click: _vm.resetFilters } }, [
                _vm._v("Reset")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.templateList,
          fields: _vm.templateFields,
          "select-mode": "single",
          "selected-variant": "success",
          selectable: "",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
          "filter-included-fields": _vm.filterFields
        },
        on: { "row-selected": _vm.onRowSelected },
        scopedSlots: _vm._u([
          {
            key: "cell(is_public)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  attrs: { disabled: "" },
                  model: {
                    value: row.item.is_public,
                    callback: function($$v) {
                      _vm.$set(row.item, "is_public", $$v)
                    },
                    expression: "row.item.is_public"
                  }
                })
              ]
            }
          },
          {
            key: "cell(icon)",
            fn: function(row) {
              return [
                row.item.icon
                  ? _c("span", [
                      _c("i", {
                        staticClass: "mr-3 fa",
                        class: row.item.icon,
                        style: "color: " + row.item.color + ";"
                      })
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button fa fa-calendar mr-2",
                  attrs: { title: "View Audit Log" },
                  on: {
                    click: function($event) {
                      return _vm.showAuditLog(row.item, $event)
                    }
                  }
                }),
                !row.item.disable_delete
                  ? _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "row-icon-button-danger",
                      class: _vm.$config.icons.general.remove,
                      attrs: { title: "Delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteTemplate(row.item, $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "table-results" }, [
          _vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-bar" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                variant: "dark",
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "page-buttons",
              attrs: {
                variant: "dark",
                "total-rows": _vm.templateList.length,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-templates-auditlog",
            centered: "",
            size: "lg",
            title: "Templates Auditlog",
            "ok-only": "",
            "ok-title": "Close"
          }
        },
        [
          _vm.auditLogTemplate
            ? _c("AuditLog", {
                attrs: {
                  related_type: "template",
                  related_id: _vm.auditLogTemplate.template_id
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }