<template>
  <div class="admin-task">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
        <i class="mx-1 icon-button" :class="$config.icons.admin.templates" v-b-tooltip title="New Template" @click="createTemplate"></i>

      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Templates Admin</h2></b-card-header>
        <b-card-body>
          <admin-template-list @row-selected="selectTemplate" ref="templateList"></admin-template-list>
        </b-card-body>
      </b-card>
    </div>
    <!--    Create Edit Modal Template-->
<!--    <b-modal id="modal-edit-product" centered class="modal-content" size="xl" hide-footer title="Edit Product">-->
<!--      <admin-edit-product is-modal modal="modal-edit-product" v-on:save="refreshList()"></admin-edit-product>-->
<!--    </b-modal>-->
  </div>
</template>

<script>

import AdminTemplateList from '@/components/admin/AdminTemplateList.vue'

export default {
  name: 'admin-templates',
  components: {
    AdminTemplateList,
  },
  data: function () {
    return {
      selectedUsers: []
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectTemplate: function (template) {
      console.log(template)
      if (template) {
        this.$router.push({ path: `/admin/templates/${template.template_id}` })
      }
    },
    createTemplate: function () {
      this.$router.push({ path: `/admin/templates/new` })
    },
    refreshList: function () {
      this.$refs.templateList.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-task {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
